<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="join_team_popup__container">
                <div class="join_team_popup__container-title">
                  {{props.title}}
                </div>
                <div class="btn_group">
                    <button class="btn-md btn-blue shadow" @click="close">OK</button>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/join-team-popup/JoinTeamPopup.ts" />
<style lang="scss" scoped src="@/presentation/components/modals/join-team-popup/JoinTeamPopup.scss" />
