import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import routePath from "@/commons/RoutePath";
export default defineComponent({
    name: "JoinTeamPopup",
    props: ["title"],
    setup: function (props, _a) {
        var emit = _a.emit;
        var router = useRouter();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
        });
        function close() {
            router.push(routePath.TEAMS);
            emit("close");
        }
        return {
            state: state,
            props: props,
            close: close
        };
    }
});
